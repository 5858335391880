export default [
    {
        path: 'speedReading',
        name: 'speedReading',
        component: () => import( /* webpackChunkName: "speedreading" */ './Index.vue'),
        children: [
            // {
            //     path: 'checkpoint',
            //     name: 'checkpoint',
            //     component: () => import( /* webpackChunkName: "speedreading" */ './pages/Checkpoint.vue')
            // },
            {
                path: 'evaluation',
                name: 'evaluation',
                component: () => import( /* webpackChunkName: "speedreading" */ './pages/Evaluation.vue'),
                props: true // 允许通过路由参数传递 props
            },
            {
                path: 'training',
                name: 'training',
                component: () => import( /* webpackChunkName: "speedreading" */ './pages/Training.vue')
            },
            {
                path: 'selectChapter',
                name: 'selectChapter',
                component: () => import( /* webpackChunkName: "speedreading" */ './pages/SelectChapter.vue'),
                props: true // 允许通过路由参数传递 props
            },
            {
                path: 'pagingReading',
                name: 'pagingReading',
                component: () => import( /* webpackChunkName: "speedreading" */ './pages/Reading/pagingReading.vue')
            },
            {
                path: 'reading',
                name: 'reading',
                component: () => import( /* webpackChunkName: "speedreading" */ './pages/Reading/Index.vue'),
                props: true // 允许通过路由参数传递 props
            },
            {
                path: 'testing',
                name: 'testing',
                component: () => import( /* webpackChunkName: "speedreading" */ './pages/Testing.vue')
            },
            {
                path: 'result',
                name: 'result',
                component: () => import( /* webpackChunkName: "speedreading" */ './pages/Result.vue')
            },
            {
                path: '/',
                redirect: '/sw/speedReading/checkpoint'
            }
        ]
    }
]