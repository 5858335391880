export default {
    state: {
        network: true, // 是否处于离线状态
        userInfo: window.localStorage.getItem("userInfo") ? JSON.parse(window.localStorage.getItem("userInfo")) : {} //有值表示账号登录，没值表示游客
    },
    mutations: {
        setUserInfo(state, payload) {
            state.userInfo = payload;
        },
        setNetwork(state, payload) {
            state.network = payload;
        }
    },
    actions: {
        
    }
}