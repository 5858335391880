import Vue from 'vue';
import Router from 'vue-router';

import speedReadingRoutes from './views/speedReading/router.js';


Vue.use(Router);

export default new Router({
    transitionOnLoad: true, //初次加载是否启用场景切换
    saveScrollPosition: false, //在启用html5 history模式的时候生效，用于后退操作的时候记住之前的滚动条位置
    routes: [
        {
            path: '/sw',
            name: 'root',
            component: () => import( /* webpackChunkName: "sw" */ '@/App.vue'),
            children: [
                ...speedReadingRoutes,
                
                {
                    path: '/sw/gameCheckpoint',
                    name: 'gameCheckpoint',
                    component: () => import(/* webpackChunkName: "sw" */ './views/Checkpoint.vue')
                },{
                    path: '/sw/gameList',
                    name: 'gameList',
                    component: () => import(/* webpackChunkName: "sw" */ './views/GameList.vue')
                },
                {
                    path: '/sw/login',
                    name: 'login',
                    component: () => import(/* webpackChunkName: "sw" */ './views/Login.vue')
                },
                {
                    path: '/sw/home',
                    name: 'home',
                    component: () => import(/* webpackChunkName: "sw" */ './views/Home.vue')
                },
                {
                    path: '/sw',
                    redirect: '/sw/login'
                }
            ]
        },
        {
            path: '/',
            redirect: '/sw/login'
        }
    ]
})
