<template>
    <button :style="buttonStyle" :ref="ref" @click="btnClick">
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: "SwButton",
    props: {
        size: String,
        buttonStyle: {
            type: Object,
            default: () => {
                return {};
            }
        },
        sound: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            ref: this.uuid(5)
        };
    },
    methods: {
        uuid(len, radix) {
            var chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split("");
            var uuid = [],
                i;
            radix = radix || chars.length;
            if (len) {
                for (i = 0; i < len; i++)
                    uuid[i] = chars[0 | (Math.random() * radix)];
            } else {
                var r;
                uuid[8] = uuid[13] = uuid[18] = uuid[23] = "-";
                uuid[14] = "4";
                for (i = 0; i < 36; i++) {
                    if (!uuid[i]) {
                        r = 0 | (Math.random() * 16);
                        uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
                    }
                }
            }
            return uuid.join("");
        },
        btnClick(event){
            let className = event.srcElement.className
            if(event.type == 'click' && event.srcElement.className == 'sw-btn'){
                event.target.setAttribute('class',event.srcElement.className + ' '+'animated pulse')
            }
            setTimeout(() => {
                event.target.setAttribute('class',className)
            }, 500);
        },
    },
    mounted() {
        
        this.$refs[this.ref].addEventListener("click", (event) => {
            if (this.sound) {
                const _audio = document.querySelector('#button-audio');
                _audio.play();
            }
            if(event.srcElement.className.indexOf('sw-btn-')!=-1){
                this.$emit('click');
            }else{
                setTimeout(() => {
                    this.$emit('click');
                }, 1000);
            }
        });
    }
};
</script>