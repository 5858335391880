<template>
    <transition
        name="custom-classes-transition"
        enter-active-class="animated bounceIn"
        leave-active-class="animated bounceOut"
    >
        <div class="sw-panel" v-show="isShowPanel">
            <div class="sw-panel-title">
                <h1>{{title}}</h1>
            </div>
            <div class="sw-panel-box">
                <div class="sw-panel-body">
                    <slot name="body"></slot>
                </div>
                <div class="sw-panel-footer">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: "SwPanel",
    props: {
        title: null
    },
    created() {

    },
    data() {
        return {
            isShowPanel: true
        };
    },
    mounted() {
        // this.isShowPanel = true
    }
};
</script>
