<template>
    <transition
        name="custom-classes-transition"
        enter-active-class="animated bounceInDown"
        leave-active-class="animated fadeOut"
    >
        <div class="sw-info" v-if="msgInfo.isShow">
            <i class="sw-info-icon">!</i>
            <div class="sw-info-msg">{{msgInfo.msg}}</div>
        </div>
    </transition>
</template>

<script>
export default {
    name: "SwInfo",
    props: {
        msgInfo: {
            type: Object,
            default: () => {
                return {
                    msg: "",
                    isShow: false
                };
            }
        }
    },
    data() {
        return {};
    },
    mounted() {
        setInterval(() => {
            this.msgInfo.isShow = false;
        }, 10000);
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.sw-info {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    line-height: 5vh;
    height: 5vh;
    padding: 2vw;   
    background: rgba(0, 0, 0, 0.5);
    .sw-info-icon {
        color: #e59701;
        float: left;
        vertical-align: middle;
        font-weight: bold;
        font-size: 4vh;
        margin: 0 1vw 0 5vw;
    }
    .sw-info-msg {
        position: relative;
        color: #fff;
        z-index: 1;
        opacity: 1;
        text-align: left;
        margin: 0 0 0 12vw;
    }
}
</style>
