import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import SwStore from './views/SwStore.js';
import speedReading from './views/speedReading/store.js';

export default new Vuex.Store({
    ...SwStore,
    modules: {
        speedReading
    }
})