import collapseTransition from './transitions/collapse-transition.js';
import swButton from "./Button.vue";
import swPanel from "./Panel.vue";
import swInfo from "./Info.vue";
import swDialog from "./dialog.vue";
import swBackOut from "./backOut.vue";

const components = [
    collapseTransition,
    swButton,
    swPanel,
    swInfo,
    swDialog,
    swBackOut
];

const install = function (Vue, opts = {}) {
    if (Vue.prototype.$isServer) return;
    components.map(component => {
        if (component.name) {
            Vue.component(component.name, component);
        }
    });
};

export default {
    version: '1.0.0',
    install
};