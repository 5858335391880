<template>
  <div>
    <sw-button
        :sound="false"
        class="sw-btn-back">
      <i class="sw-btn-back-icon1" title="返回上级栏目" @click="handleOut(1)"></i>
      <i v-if="$route.path != '/sw/gameList'" class="sw-btn-back-icon2" title="返回游戏列表" @click="handleOut(3)"></i>
    </sw-button>
    <div v-if="isShowDialogBack" class="sw-back-bg">
      <transition
          name="custom-classes-transition"
          enter-active-class="animated bounceIn"
          leave-active-class="animated bounceOut"
      >
        <div class="sw-back-out">
          <i class="sw-back-out-close" @click="isShowDialogBack = false">X</i>
          <sw-button :sound="false" @click="handleOut(2)" class="sw-btn" style="padding: 2vh 2.2vw;">退出登录
          </sw-button>
          <sw-button v-if="$route.path != '/sw/gameList'" :sound="false" @click="handleOut(3)" class="sw-btn"
                     style="padding: 2vh 2.2vw;">游戏列表
          </sw-button>
          <sw-button v-if="$route.path != '/sw/gameList' && $route.path != '/sw/home'" :sound="false"
                     @click="handleOut(4)" class="sw-btn" style="padding: 2vh 2.2vw;">阅读主界面
          </sw-button>
          <sw-button :sound="false" @click="handleOut(1)" class="sw-btn" style="padding: 2vh 2.2vw;">上级栏目
          </sw-button>
        </div>
      </transition>
    </div>

    <sw-dialog :dialogInfo="dialogInfo"></sw-dialog>
  </div>
</template>

<script>

export default {
  name: "swBackOut",
  props: {
    backPre: '',
  },
  data() {
    return {
      isShowDialogBack: false,
      dialogInfo: {
        msg: "确认退出训练吗？",
        isShow: false,
        hasIcon: true,
        icon: "？"
      }
    }
  },
  methods: {
    handleOut(name) {
      if (name == 1) {
        this.$router.push(this.backPre)
      } else if (name == 2) {
        // this.$router.push('/sw/login')
        this.dialogInfo.isShow = true
      } else if (name == 3) {
        this.$router.push('/sw/gameList?id=2&name=快速阅读')
      } else if (name == 4) {
        this.$router.push('/sw/gameList?id=2&name=快速阅读')
      }
    }
  }
}
</script>