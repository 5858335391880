import config from './config.js';
import api from './api.js';

export default {
    namespaced: true,
    state: {
        token: sessionStorage.getItem('token') || '',
        yycdid: Number(sessionStorage.getItem('yycdid')) || '',
        userInfo: {
            yhid: JSON.parse(sessionStorage.getItem('userInfo')) ? JSON.parse(sessionStorage.getItem('userInfo')).yhid : '',
            realname: JSON.parse(sessionStorage.getItem('userInfo')) ? JSON.parse(sessionStorage.getItem('userInfo')).realname : '',
            zhid: JSON.parse(sessionStorage.getItem('userInfo')) ? JSON.parse(sessionStorage.getItem('userInfo')).zhid : '',
            zhmc: JSON.parse(sessionStorage.getItem('userInfo')) ? JSON.parse(sessionStorage.getItem('userInfo')).zhmc : ''
        },
        version: config.version,
        rankList: [], // 等级列表
        currentRank: { // 选中的等级
            rankId: null, // 等级ID
            rankName: null // 等级名字
        },
        currentSpeedId: null, // 选中的速度id
        currentSpeed: null, // 选中的速度
        currentSpeedMin: null, // 选中的速度最大值
        currentSpeedMax: null, // 选中的速度最小值
        books: [], // 书的列表
        // bookId: window.localStorage.getItem("bookId") ? JSON.parse(window.localStorage.getItem("bookId")) : undefined, // 选中的书的id
        bookDetail: null, // 书的详细内容
        anserGrade: null, // 答题得分数
        currentCheckPoint: window.localStorage.getItem("instantaneous-currentCheckPoint") ? JSON.parse(window.localStorage.getItem("instantaneous-currentCheckPoint")) : undefined, // 当前checkPoint
        totalTimes:0,//阅读文章总共耗时
        specialBookChapterId:null, //章节id
        selectedSpeed: '',
        book: null,
        bookContent:null,
        xm: '',
        fellow: '',
        term: '',
    },
    mutations: {
        setSpeed(state, speed) {
            state.selectedSpeed = speed;
        },
        setBook(state, book) {
            state.book = book;
        },
        setContent(state,content){
            state.bookContent=content
        },
        // 存储token
        setToken(state, token) {
            state.token = token
        },
        // 存储用户信息
        setInfo(state, userInfo) {
            state.userInfo = userInfo
        },
        // 存储应用信息
        setYycdid(state, yycdid) {
            state.yycdid = yycdid
        },
        setRankList(state, payload) {
            state.rankList = payload;
        },
        setCurrentRank(state, payload) {
            state.currentRank = payload;
        },
        setCurrentSpeedId(state, payload) {
            state.currentSpeedId = payload;
        },
        setCurrentSpeed(state, payload) {
            state.currentSpeed = payload;
        },
        setCurrentSpeedMin(state, payload) {
            state.currentSpeedMin = payload;
        },
        setCurrentSpeedMax(state, payload) {
            state.currentSpeedMax = payload;
        },
        setBooks(state, payload) {
            state.books = payload;
        },
        setBookId(state, payload) {
            state.bookId = payload;
        },
        setBooksDetail(state, payload) {
            state.bookDetail = payload;
        },
        setAnserGrade(state, payload) {
            state.anserGrade = payload;
        },
        setTotalTimes(state, payload) {
            state.totalTimes = payload;
        },
        setBookSelected(state, payload) {
            state.currentSpeedId = payload.speedId;
            state.currentSpeed = payload.speedDefault;
            state.currentSpeedMin = payload.speedMin;
            state.currentSpeedMax = payload.speedMax;
        },
        setCurrentCheckPoint(state, payload) {
            state.currentCheckPoint = payload;
        },
        setSpecialBookChapterId(state, payload) {
            state.specialBookChapterId = payload;
        },
        setXm(state, xm) {
            state.xm = xm;
        },
        setVip(state, fellow) {
            state.fellow = fellow
        },
        setVipTime(state, term) {
            state.term = term
        },

    },
    actions: {
    }
}