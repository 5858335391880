<template>
    <div class="sw-game">
        <router-view></router-view>
        <template v-show="false">
            <audio id="button-audio" src="static/clickSound.wav" type="audio/wav"></audio>
            <audio id="background-audio" :src="'static/'+backgroundAudio+'.mp3'" type="audio/mp3" loop></audio>
        </template>
    </div>
</template>

<script>
export default {
    data(){
        return{
            backgroundAudio: 1
        }
    },
    watch:{
        $route: {
            handler: function(val, oldVal){
                // 进入阅读界面随机
                if(val.path == '/sw/speedReading/pagingReading' || val.path == '/sw/speedReading/reading'){
                    this.backgroundAudio =  Math.floor(Math.random()*10+1)
                }
            },
            // 深度观察监听
            deep: true
        }
    },
    mounted() {
        const _network = navigator.connection.type !== 'none';
        this.$store.commit('setNetwork', _network);

        if ('_cordovaNative' in window) {
            document.addEventListener('offline', function () {
                this.$store.commit('setNetwork', false);
            });
            document.addEventListener('online', function () {
                this.$store.commit('setNetwork', true);
            });
            window.history.pushState(null, null, "#");

            this.$router.push('/sw/login');
        }
    }
}
</script>

<style lang="scss">
@import './scss/index.scss';
</style>