import bookConfig from './books.js';

export default {
    version: 'v1.0',
    rankList: [
        {
            "rankId":"1",
            "rankName":"初级",
            "sort":1,
            "speeds":[
                {"speedId":"1","speedName":"200-1000/分钟","rankId":"1","speedSort":null,"speedDefault":200,"speedMin":100,"speedMax":1000},
                {"speedId":"2","speedName":"1000-2000/分钟","rankId":"1","speedSort":2,"speedDefault":1000,"speedMin":1000,"speedMax":2000},
                {"speedId":"3","speedName":"2000-3000/分钟","rankId":"1","speedSort":3,"speedDefault":2000,"speedMin":2000,"speedMax":3000}
            ]
        },
        {
            "rankId":"2",
            "rankName":"中级",
            "sort":2,
            "speeds":[
                {"speedId":"4","speedName":"3000-4000/分钟","rankId":"2","speedSort":1,"speedDefault":3000,"speedMin":3000,"speedMax":4000},
                {"speedId":"5","speedName":"4000-5000/分钟","rankId":"2","speedSort":2,"speedDefault":4000,"speedMin":4000,"speedMax":5000},
                {"speedId":"6","speedName":"5000-6500/分钟","rankId":"2","speedSort":3,"speedDefault":5000,"speedMin":5000,"speedMax":6500},
                {"speedId":"7","speedName":"6500-8000/分钟","rankId":"2","speedSort":4,"speedDefault":6500,"speedMin":6500,"speedMax":8000}
            ]
        },
        {
            "rankId":"3",
            "rankName":"高级",
            "sort":3,
            "speeds":[
                {"speedId":"8","speedName":"8000-9500/分钟","rankId":"3","speedSort":1,"speedDefault":8000,"speedMin":8000,"speedMax":9500},
                {"speedId":"9","speedName":"9500-11000/分钟","rankId":"3","speedSort":2,"speedDefault":9500,"speedMin":9500,"speedMax":11000},
                {"speedId":"10","speedName":"11000-12500/分钟","rankId":"3","speedSort":3,"speedDefault":11000,"speedMin":11000,"speedMax":125000},
                {"speedId":"11","speedName":"12500-14000/分钟","rankId":"3","speedSort":4,"speedDefault":12500,"speedMin":125000,"speedMax":14000}
            ]
        },
        {
            "rankId":"4",
            "rankName":"神级",
            "sort":4,
            "speeds":[
                {"speedId":"12","speedName":"14000-15000/分钟","rankId":"4","speedSort":1,"speedDefault":14000,"speedMin":14000,"speedMax":15000},
                {"speedId":"13","speedName":"15000-16000/分钟","rankId":"4","speedSort":2,"speedDefault":15000,"speedMin":15000,"speedMax":16000},
                {"speedId":"14","speedName":"16000-17000/分钟","rankId":"4","speedSort":3,"speedDefault":16000,"speedMin":16000,"speedMax":17000}
            ]
        }
    ],
    pageSize: 9,
    booksList: bookConfig.booksList,
    books: bookConfig.books,
    tests: bookConfig.tests,
}