<template>
  <div>
    <div class="sw-back-bg" v-if="dialogInfo.isShow" @click="clickOut">
      <transition name="custom-classes-transition" enter-active-class="animated zoomIn">
        <div class="sw-back-out" v-if="dialogInfo.isShow" :style="{'width':this.dialogInfo.width}">
          <slot name="body">
            <i class="sw-back-out-icon" v-if="dialogInfo.icon">{{ dialogInfo.icon }}</i>
            <p>{{ dialogInfo.msg }}</p>
          </slot>
          <sw-button v-if="dialogInfo.hasIcon" @click="dialogInfo.isShow = false" class="sw-btn-close">取消</sw-button>
          <sw-button v-if="dialogInfo.hasIcon" :sound="false" @click="handleLoginOut" class="sw-btn">确定</sw-button>
        </div>
      </transition>
    </div>
    <div class="sw-back-bg" v-if="dialogInfos.isShow" @click="clickOut2">
      <transition name="custom-classes-transition" enter-active-class="animated zoomIn">
        <div class="sw-back-out" v-if="dialogInfos.isShow" :style="{'width':this.dialogInfos.width}">
          <slot name="body">
            <i class="sw-back-out-icon" v-if="dialogInfos.icon">{{ dialogInfos.icon }}</i>
            <p>{{ dialogInfos.msg }}</p>
          </slot>
          <sw-button v-if="dialogInfos.hasIcon" @click="dialogInfos.isShow = false" class="sw-btn-close">取消
          </sw-button>
          <sw-button v-if="dialogInfos.hasIcon" :sound="false" @click="handleLoginOut" class="sw-btn">确定</sw-button>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "swDialog",
  props: {
    dialogInfo: {
      type: Object,
      default: () => {
        return {
          width: ' ',
          icon: '？',
          msg: " ",
          isShow: false,
          hasIcon: {
            type: Boolean,
            default: true
          }
        };
      }
    },
    dialogInfos: {
      type: Object,
      default: () => {
        return {
          width: ' ',
          icon: '？',
          msg: " ",
          isShow: false,
          hasIcon: {
            type: Boolean,
            default: true
          }
        };
      }
    }
  },
  data() {
    return {};
  },
  methods: {
    // 点击其他地方退出弹框
    clickOut(event) {
      if (document.getElementsByClassName('sw-back-bg')[0] == event.target) {
        if (this.dialogInfo && this.dialogInfo.hasIcon) {
          this.dialogInfo.isShow = false
        }
      }
    },
    clickOut2(event) {
      if (document.getElementsByClassName('sw-back-bg')[0] == event.target) {
        if (this.dialogInfos && this.dialogInfos.hasIcon) {
          this.dialogInfos.isShow = false
        }
      }
    },
    handleLoginOut() {
      this.$router.push('/sw/login');
      window.localStorage.removeItem('userInfo');
      this.$store.commit("setUserInfo", "");
    },
  }
};
</script>
