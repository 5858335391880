import Vue from 'vue';

import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import SvgIcon from './components/svgIcon/svgIcon.vue';
// 自定义组件
import swComponents from "./components";

Vue.use(swComponents);
Vue.use(ElementUI)
Vue.component('svg-icon', SvgIcon);
Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
